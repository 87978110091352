import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { FAQ } from "./pages/FAQ";
import { ThanksForSigningUp } from "./pages/ThanksForSigningUp";
import reportWebVitals from "./reportWebVitals";
// import { Router, Route, Routes } from "react-router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const root = ReactDOM.createRoot(document.getElementById("root"));
const org_jql_link = "https://marketplace.atlassian.com/apps/1234620/orgjql?hosting=cloud&tab=overview";
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home ctaLink={org_jql_link} />} />
                <Route exact path="/faq" element={<FAQ ctaLink={org_jql_link} />} />
                <Route exact path="/about" element={<About ctaLink={org_jql_link} />} />
                <Route exact path="/thanks-for-signing-up" element={<ThanksForSigningUp ctaLink={org_jql_link} />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import "../sass/forthix.scss";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import rino_jose from "../images/Rino Jose.png";

export function About({ ctaLink }) {
    mixpanel.track("Page View", { "page name": "About" });

    return (
        <div>
            <NavBar activePage="about" ctaLink={ctaLink} />
            <hr />
            <div className="container block">
                <div className="block columns is-centered">
                    <section className="column is-two-thirds ">
                        <h1 className="title is-2">Forthix Origin Story</h1>
                        <h2 className="title is-3">
                            Customizing Jira in large organizations creates overhead that’s hard to address
                        </h2>
                        <p className="block">
                            Once upon a time, program managers at LinkedIn were using Jira, Confluence and spreadsheets
                            to track, manage, and report on the status of important projects. They created custom
                            processes and workflows that management liked, but it was taking a lot of effort for them to
                            keep it all running. They asked LinkedIn's Jira admin for help, but there was only so much
                            he could do with Jira, so he told them to talk to{" "}
                            <a href="https://www.linkedin.com/in/rinoj" target="_blank">
                                Rino
                            </a>
                            , who was on LinkedIn's Tools Team.
                        </p>
                        <p className="block">
                            Rino was already busy consolidating various project management tools that had grown from
                            one-off utilities into unwieldy tools. He wasn't keen on stuffing more functionality into
                            these because that would make things worse. He had already tried building the "one tool"
                            based on the requirements of the engineering teams, but by the time he was done, teams had
                            come and gone, and requirements had changed again. He wasn't sure if there was a way to
                            solve this without arguing for a larger team, which certainly would not be approved.
                        </p>
                        <p className="block">
                            Reflecting on his failed "one tool" endeavor and the impracticality of managing multiple
                            tools with limited staff, Rino contemplated an alternative approach. What if he could
                            swiftly develop numerous small tools instead? Despite skepticism from the Jira Admin, who
                            foresaw challenges in managing a multitude of tools, Rino remained resolute in his pursuit.
                            While unsure of success, he was determined to explore this path.
                        </p>

                        <h2 className="title is-3">Embrace customization by making tools tweakable</h2>
                        <p className="block">
                            In order for this to work, he'd need something that could be tweaked at runtime for both the
                            frontend and the backend of the app. Basically, he'd need a programming language that could
                            run in both environments and that could be revised at runtime in a coordinated way. He
                            looked around, but there wasn't anything that could do that, so he thought about the
                            simplest thing that could work.
                        </p>
                        <p className="block">
                            The simplest language Rino could think of was FORTH. But it wasn’t designed to run in a
                            web-based world, so he created a kind of FORTH that could. What he came up with wasn’t FORTH
                            anymore, but it was FORTH-like, so he named it{" "}
                            <a href="https://github.com/linkedin/forthic" target="_blank" rel="noreferrer">
                                Forthic
                            </a>
                            .
                        </p>
                        <p className="block">
                            Forthic enabled Rino to build tweakable one-off project tools quickly. Within a year, he had
                            built a hundred of these tools ranging from Confluence reports, to dashboards, to intake
                            forms, and to automations. But as the Jira admin had warned, it was getting hard to maintain
                            a hundred tools on his own.
                        </p>

                        <h2 className="title is-3">Learn how to tweak tools</h2>
                        <p className="block">
                            One day, Rino realized that because Forthic programs were concise and tweakble, even
                            non-technical people should be able to revise their own tools. So he opened up the editing
                            of these tools to everyone at LinkedIn and began teaching them how to use Forthic. The
                            number of tools grew to 200. Then to 300. Each year, the growth rate had increased. By the
                            time Rino had left LinkedIn in 2023, there were over 800 Forthic apps running at LinkedIn,
                            saving 25,000 hours of manual effort per year – for every 12 program managers, Forthic saved
                            the equivalent manual effort of one more program manager, a “baker’s dozen” ROI.
                        </p>

                        <h2 className="title is-3">
                            Other organizations could benefit from Forthic, but how could they get it?
                        </h2>
                        <p className="block">
                            Because of how Forthic was designed, Rino knew he could get it running on Atlassian's Forge
                            platform and into Atlassian’s marketplace so other organizations could see the same
                            benefits. He built a prototype...then an MVP that could roll tickets up by manager...then a
                            plugin that let you customize JQL functions at runtime in Forthic. That's how Forthix began.
                        </p>
                        <p className="block">
                            Forthix is just getting started. We’re excited to see how you’ll use it to solve your
                            problems and make your work easier, and we're ready to help!{" "}
                            <a href="https://marketplace.atlassian.com/vendors/1225195/forthix-llc" target="_blank">
                                Give Forthix a try today
                            </a>
                            , leave a review, and we'll plant a tree for you!
                        </p>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    );
}

import React, { useEffect, useState } from "react";

export function Footer() {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p className="subtitle is-size-6">Forthix LLC &copy; {new Date().getFullYear()} All rights reserved.</p>
            </div>
        </footer>
    );
}

import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import "../sass/forthix.scss";
import tweak_icon from "../images/tweak_icon.svg";
import hifi_icon from "../images/hifi_icon.svg";
import concise_icon from "../images/concise_icon.svg";
import logo_png from "../images/logo.png";
import hero_png from "../images/hero.png";
import dynamic_history_png from "../images/dynamic-history.png";
import org_rollup_png from "../images/org-rollup.png";
import flex_forms_png from "../images/flex-forms.png";
import custom_core_png from "../images/custom-core.png";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";

function SolutionTile({ title, description, image }) {
    return (
        <div className="tile is-parent">
            <article className="tile is-child notification p-5 solution-tile">
                <p className="subtitle is-size-4">{title}</p>
                <figure className="image">{image}</figure>
                {description}
            </article>
        </div>
    );
}

function HiFiTile({ title, description, icon }) {
    return (
        <div className="tile is-parent">
            <article className="tile is-child notification p-5 fidelity-tile">
                <div className="columns is-mobile">
                    <p className="column subtitle is-size-4  mr-2">{title}</p>
                    <img src={icon} alt="" className="hifi-img column is-one-fifth pt-0" />
                </div>
                {description}
            </article>
        </div>
    );
}

export function Home({ ctaLink }) {
    mixpanel.track("Page View", { "page name": "Home" });

    const hero = (
        <section className="hero is-medium block is-info">
            <div className="hero-body">
                <div className="columns">
                    <div className="column is-one-third">
                        <p className="title is-size-1">Tweakable Jira Apps</p>
                        <p className="is-size-5">
                            Start with Jira reports that real managers at real companies have used for years&mdash;and
                            tweak them for your exact needs
                        </p>
                        <p>
                            <a
                                href={ctaLink}
                                target="_blank"
                                className="button mt-6 subtitle is-primary"
                                rel="noreferrer"
                            >
                                <strong>Try Forthix today!</strong>
                            </a>
                        </p>
                    </div>
                    <div className="column is-two-thirds">
                        <figure class="image is-16by9">
                            <iframe
                                title="Why Forthix?"
                                class="has-ratio"
                                width="320"
                                height="180"
                                src="https://www.youtube.com/embed/RhkUXejU7HI"
                                frameborder="0"
                                allowfullscreen
                            ></iframe>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );

    const proven_solutions = (
        <div className="block">
            <section className="container is-fullhd">
                <h1 className="title is-size-2 has-text-centered has-tight-spacing">
                    Start from <span className="is-italic">proven</span> solutions
                </h1>
                <div className="columns is-centered">
                    <div className="column is-four-fifths">
                        <p>
                            Every report is based on years of daily use by hundreds of Technical Program Managers in
                            real organizations solving really tough problems. Each is the culmination of dozens of
                            enhancements from execs, managers, and technical program managers. Leverage Jira to make
                            more impactful decisions and drive action&mdash;give Forthix a try.
                        </p>
                    </div>
                </div>
                <div className="tile is-ancestor m-2">
                    <SolutionTile
                        title="Dynamic History"
                        description={
                            <div className="content">
                                <p>
                                    Instead of taking snapshots of data, use the history that Jira automatically
                                    provides.
                                </p>
                                <ul>
                                    <li>Figure out the status of tickets at any point in time</li>
                                    <li>Compute time in status</li>
                                    <li>Examine trends at any granularity</li>
                                    <li>Surface a wealth of insight</li>
                                </ul>
                            </div>
                        }
                        image={
                            <a href="https://youtu.be/Cd7Uk5i6sHg" target="_blank" rel="noreferrer">
                                <img className="solution-img" src={dynamic_history_png} alt="" />
                            </a>
                        }
                    />
                    <SolutionTile
                        title="Org Rollups"
                        description={
                            <div className="content">
                                <p>
                                    Select all the tickets that roll up into managers at any level. Org rollups can do
                                    this&hellip;and more.
                                </p>
                                <ul>
                                    <li>Summarize what needs to be done at the right level</li>
                                    <li>
                                        Send custom reports to the right person with the right things to focus on right
                                        now
                                    </li>
                                    <li>Absolutely essential for all but the smallest teams</li>
                                </ul>
                            </div>
                        }
                        image={<img className="solution-img" src={org_rollup_png} alt="" />}
                    />
                    <SolutionTile
                        title="Flex Forms"
                        description={
                            <div className="content">
                                <p>
                                    When you need more than a Jira Create Form but not a whole service management
                                    solution.
                                </p>
                                <ul>
                                    <li>Structure intake info without custom fields</li>
                                    <li>Add multi-step and branching forms</li>
                                    <li>Relabel fields</li>
                                    <li>Direct to self-service when it makes more sense than filing a ticket</li>
                                </ul>
                            </div>
                        }
                        image={<img className="solution-img" src={flex_forms_png} alt="" />}
                    />
                    <SolutionTile
                        title="Custom Core"
                        description={
                            <div className="content">
                                <p>Your team is unique&mdash;shouldn't your Jira be, too?</p>
                                <ul>
                                    <li>Create custom reporting components and use them in multiple reports</li>
                                    <li>
                                        Add JQL functions that roll tickets up by manager, region, team and use them
                                        anywhere in Jira
                                    </li>
                                    <li>Drive consistency from a common, custom core </li>
                                </ul>
                            </div>
                        }
                        image={<img className="solution-img" src={custom_core_png} alt="" />}
                    />
                </div>
            </section>
        </div>
    );

    const high_fidelity = (
        <section className="hero is-warning">
            <div className="hero-body">
                <div className="block">
                    <section className="container">
                        <h1 className="title is-size-2 has-text-centered has-tight-spacing">
                            Flexibility + <span className="is-italic">High-fidelity</span>
                        </h1>
                        <div className="columns is-centered">
                            <div className="column is-three-quarters">
                                <p>
                                    The flexibility of Forthix apps comes from a "high-fidelity" approach to solving
                                    problems. Most tools summarize data as they go&mdash;effectively destroying
                                    information at each step. Forthix apps preserve data using a methodology called
                                    "categorical coding". Information is preserved at each step and is always available.
                                </p>
                            </div>
                        </div>

                        <div className="tile is-ancestor m-2">
                            <HiFiTile
                                title={
                                    <span>
                                        Tweakable
                                        <br />
                                        by Design
                                    </span>
                                }
                                description={
                                    <p>
                                        When data is preserved at every step of report creation, it's easy to customize
                                        a report using small changes. Reports are tweakable by design.
                                    </p>
                                }
                                icon={tweak_icon}
                            />
                            <HiFiTile
                                title={
                                    <span>
                                        High-fidelity
                                        <br />
                                        Solutions
                                    </span>
                                }
                                description={
                                    <p>
                                        High-fidelity solutions are in 1-to-1 correspondence with the problems they
                                        solve. Small changes in a problem require small changes in the solution.
                                    </p>
                                }
                                icon={hifi_icon}
                            />
                            <HiFiTile
                                title={
                                    <span>
                                        Concise <br />
                                        Code
                                    </span>
                                }
                                description={
                                    <p>
                                        A high-fidelity approach means your data is never far away. That means less
                                        code, less maintenance, and more results.
                                    </p>
                                }
                                icon={concise_icon}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
    return (
        <div>
            <NavBar activePage="home" ctaLink={ctaLink} />
            {hero}
            {proven_solutions}
            {high_fidelity}
            <Footer />
        </div>
    );
}

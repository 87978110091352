import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import "../sass/forthix.scss";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import rino_jose from "../images/Rino Jose.png";

export function FAQ({ ctaLink }) {
    mixpanel.track("Page View", { "page name": "FAQ" });

    return (
        <div>
            <NavBar activePage="about" ctaLink={ctaLink} />
            <hr />

            <div className="container block">
                <div className="block columns is-centered">
                    <section className="column is-two-thirds ">
                        <h1 className="title is-2">How Forthix is Different</h1>
                        <p className="block">
                            Forthix is <i>not</i> a low/no-code product. Low-code approaches only solve your problem
                            partway. Forthix provides <i>high-fidelity</i> solutions that solve your problem faithfully.
                            This is what makes Forthix apps tweakable. When a solution is 1-1 with a problem, then
                            changes in your problem map cleanly to changes (i.e., tweaks) in your solution.
                        </p>

                        <p className="block">
                            High fidelity solutions also preserve data. Most reports "destroy" information when they
                            summarize it. When you see a typical pie chart, can you do anything with it beyond see the
                            percentage and the count? In Forthix, the data that went into the pie chart is{" "}
                            <i>still in the pie chart</i>. You can see the tickets that went into each pie slice, see
                            the assignees and the managers, and send notifications to everyone involved.
                        </p>

                        <p className="block">
                            Every Forthix app is the culmination years of use by thousands of real people. Each app
                            contains the learnings and feedback from dozens of TPMs, managers, and execs. Instead of
                            starting with a generic 80% solution, start from something that's proven in real situations
                            and then tweak it to make it your own.
                        </p>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
}

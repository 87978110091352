import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import "../sass/forthix.scss";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import rino_jose from "../images/Rino Jose.png";
import dynamic_status_history from "../images/dynamic-status-history.png";

export function ThanksForSigningUp() {
    // mixpanel.track("Page View", { "page name": "Home" });

    return (
        <div>
            <NavBar />
            <hr />
            <div className="container block">
                <div className="block columns is-centered">
                    <section className="column is-two-thirds ">
                        <h1 className="title is-2">Thanks for signing up!</h1>
                        <p className="block">
                            I'll be sending you an email shortly with info on how to install the private beta version of
                            Forthix. In the meantime, please feel free to look over the user documentation for the
                            "Dynamic Status History" app:
                        </p>
                        <p className="block has-text-centered">
                            <a
                                href="https://forthix.atlassian.net/wiki/spaces/USERDOCS/pages/40534063/Dynamic+Status+History+App"
                                alt="Dynamic History App"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={dynamic_status_history}
                                    width={300}
                                    className="with-shadow"
                                    alt="Dynamic Status History"
                                />
                            </a>
                        </p>

                        <p className="block">
                            <img src={rino_jose} alt="Rino Jose" width={60} className="mr-3 about-img" />
                            <i>Rino Jose, Founder of Forthix</i>{" "}
                        </p>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    );
}

import React, { useEffect, useState } from "react";
import logo_png from "../images/logo.png";

export function NavBar({ activePage, ctaLink }) {
    const [burgerHidden, setBurgerHidden] = useState(true);

    function toggleBurger() {
        console.log("Toggle burger!");
        setBurgerHidden(!burgerHidden);
    }
    const burger_active = burgerHidden ? "" : "is-active";
    const home_active = activePage === "home" ? "active" : "";
    const about_active = activePage === "about" ? "active" : "";

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item pr-1" href="/">
                    <img src={logo_png} alt="Forthix" />
                </a>
                <a className="navbar-item has-text-weight-800 is-size-3 pl-0 mr-6" href="/">
                    Forthix
                </a>

                <span
                    role="button"
                    className={`navbar-burger ${burger_active}`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    onClick={toggleBurger}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </span>
            </div>

            <div id="navbarBasicExample" className={`navbar-menu ${burger_active}`}>
                <div className="navbar-start">
                    <a href="/" className="navbar-item">
                        Home
                    </a>

                    <a href="/about" className="navbar-item">
                        About
                    </a>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <a href={ctaLink} target="_blank" className="button is-primary" rel="noreferrer">
                                <strong>Try Forthix</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
